














import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  FormSection,
  FormSubmissionValidationError,
  FormConstructorData,
  FormField,
  FormValue,
} from 'client-website-ts-library/types/Forms';
import { IFormField } from 'client-website-ts-library/types/Forms/FieldTypes';

// Field components
import FormDateField from './FieldTypes/FormDateField.vue';
import FormDropdownField from './FieldTypes/FormDropdownField.vue';
import FormEmailField from './FieldTypes/FormEmailField.vue';
import FormStaticTextField from './FieldTypes/FormStaticTextField.vue';
import FormTelField from './FieldTypes/FormTelField.vue';
import FormTextareaField from './FieldTypes/FormTextareaField.vue';
import FormTextField from './FieldTypes/FormTextField.vue';

// Big field types get loaded in async for more speeds
const FormGraphicalPDFField = () => import('./FieldTypes/PDFs/FormGraphicalPDFField.vue');
const FormVideoUploadField = () => import('./FieldTypes/FormVideoUploadField.vue');
const FormMultiFileUploaderField = () => import('./FieldTypes/MultiFileUploader/FormMultiFileUploaderField.vue');
const FormPriorityListField = () => import('./FieldTypes/FormPriorityListField.vue');

@Component({
  components: {
    FormDateField,
    FormDropdownField,
    FormEmailField,
    FormStaticTextField,
    FormTelField,
    FormTextareaField,
    FormTextField,
    FormGraphicalPDFField,
    FormVideoUploadField,
    FormMultiFileUploaderField,
    FormPriorityListField,
  },
})
export default class Section extends Vue {
  @Prop({ required: true })
  private readonly def!: FormSection;

  @Prop({ required: true })
  private readonly errors!: FormSubmissionValidationError[];

  @Prop({ required: true })
  private readonly formContext!: FormConstructorData;

  @Prop({ required: true })
  private readonly formType!: string;

  getProps(field: FormField): object {
    return {
      def: field,
      formContext: this.formContext,
      formType: this.formType,
      errors: this.errors.filter((e) => e.Key === field.Key),
    };
  }

  handleInput(fieldDef: FormField): void {
    this.$emit('field_input', fieldDef);
  }

  collectData(): FormValue[] {
    return this.def.FormFields.map((field) => ((this.$refs[field.FieldId] as Vue[])[0] as unknown as IFormField).getValue());
  }

  setData(values: FormValue[]): void {
    this.def.FormFields.forEach((field) => {
      const val = values.find((v) => v.Key === field.Key);

      if (val === undefined || val.Value === null) return;

      ((this.$refs[field.FieldId] as Vue[])[0] as unknown as IFormField).setValue(val);
    });
  }
}
